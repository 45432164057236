<template>
  <v-container>
    <v-row class="text-h5">
      <v-col cols="10"> Production Queue </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-card flat outlined>
          <v-card-title>Cut</v-card-title>
          <v-card-subtitle>These items need parts cut</v-card-subtitle>
          <v-divider />
          <v-list>
            <v-list-item v-for="(item, index) in notStarted" :key="index">
              <queue-component :item="item" v-on:update="updateLineItems()" />
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card flat outlined>
          <v-card-title>Glue</v-card-title>
          <v-card-subtitle
            >These items are cut but need to be glued up</v-card-subtitle
          >
          <v-divider />
          <v-list>
            <v-list-item v-for="(item, index) in cut" :key="index">
              <queue-component :item="item" v-on:update="updateLineItems()" />
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card flat outlined>
          <v-card-title>Finish</v-card-title>
          <v-card-subtitle
            >These items are ready to be sanded and finished</v-card-subtitle
          >
          <v-divider />
          <v-list>
            <v-list-item v-for="(item, index) in glued" :key="index">
              <queue-component :item="item" v-on:update="updateLineItems()" />
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import QueueComponent from '../components/QueueComponent.vue'
export default {
  components: { QueueComponent },
  name: 'Queue',
  data: () => ({
    lineItems: [],
  }),
  computed: {
    notStarted() {
      return this.lineItems.filter((li) => {
        return li.status == 'Need'
      })
    },
    cut() {
      return this.lineItems.filter((li) => {
        return li.status == 'Cut'
      })
    },
    glued() {
      return this.lineItems.filter((li) => {
        return li.status == 'Glued'
      })
    },
  },
  async mounted() {
    this.updateLineItems()
  },
  methods: {
    async updateLineItems() {
      let res = await this.axios.get('line-items/?status_ne=Complete')
      this.lineItems = res.data.sort((a, b) => {
        return a.createdAt < b.createdAt
      })
    },
  },
}
</script>
<style></style>

<template>
  <v-card
    :color="color"
    flat
    outlined
    style="margin-bottom:8px; margin-top:8px;"
  >
    <v-card-text>
      <div v-if="item.order">
        Order {{ item.order.shopifyJson.name }}
        <span v-if="item.order.shopifyJson.tags.includes('urgent')">
          <v-chip color="red">Urgent</v-chip>
        </span>
      </div>
      <div>
        {{ item.name }}
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn icon x-large @click="regressItem()">
        <v-icon>
          arrow_back
        </v-icon>
      </v-btn>
      <v-spacer />
      <v-btn icon x-large @click="advanceItem()">
        <v-icon>
          arrow_forward
        </v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import strapiApi from '../mixins/strapiApi'
export default {
  name: 'QueueComponent',
  props: { item: Object, color: String, arrowColor: String },
  mixins: [strapiApi],
  data: () => ({
    statuses: ['Need', 'Cut', 'Glued', 'Complete'],
  }),
  methods: {
    async advanceItem() {
      const i = this.statuses.findIndex((status) => status == this.item.status)
      const newStatus = this.statuses[i + 1]
      await this.updateLineItem(this.item.id, { status: newStatus })
      this.$emit('update')
    },
    async regressItem() {
      const i = this.statuses.findIndex((status) => status == this.item.status)
      const newStatus = i == 0 ? this.statuses[0] : this.statuses[i - 1]
      await this.updateLineItem(this.item.id, { status: newStatus })
      this.$emit('update')
    },
  },
}
</script>

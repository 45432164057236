export default {
  methods: {
    strapiGet(endpoint){
      return this.axios
        .get(endpoint)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          console.error(err)
        }) 
    },
    getOrders(params) {
      return this.axios
        .get(`orders?${params}`)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getProducts(params) {
      return this.axios
        .get(`products?${params}`)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getProduct(id) {
      return this.axios
        .get(`products/${id}`)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getCollections(params) {
      return this.axios
        .get(`collections${params || ''}`)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    syncOrder(id) {
      return this.axios.get(`orders/${id}/sync`)
    },
    syncEndpoint(endpoint, params) {
      return this.axios.post(`${endpoint}/import`, { params })
    },
    getOrder(id) {
      return this.axios.get(`orders/${id}`)
    },
    createShipment(shipment) {
      return this.axios.post('shipments', shipment)
    },
    createLineItem(lineItem) {
      return this.axios.post('line-items', lineItem)
    },
    getLineItem(shopifyId) {
      return this.axios.get(`line-items/?shopifyId=${shopifyId}`)
    },
    updateLineItem(id, update) {
      return this.axios.put(`line-items/${id}`, update)
    },
    updateWorkHours(id, update) {
      return this.axios.put(`work-days/${id}`, update)
    },
    deleteShipment(id) {
      return this.axios.delete(`shipments/${id}`)
    },
    getPackages() {
      return this.axios
        .get('packages')
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    updateProduct(id, product) {
      return this.axios
        .put(`products/${id}`, product)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    shipmentNotify(id, user) {
      return this.axios
        .put(`shipments/${id}/notify`, {
          notifiedBy: user,
          notificationSentAt: new Date(Date.now()),
        })
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getComments(id) {
      return this.axios
        .get(`orders/${id}/comments`)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getUser(id) {
      return this.axios
        .get(`users/${id}`)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getWorkDays(){
      const today = (new Date(Date.now()).toISOString().slice(0,10))
      return this.axios.get(`work-days?Date_gte=${today}`)
      .then(res => {
        return res.data
      })
      .catch(err => {
        console.error(err)
      })
    }
  },
}
